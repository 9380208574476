import { BUILD_ID } from '$lib/globals.js';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://333a70eca1334613a16770b0fde0f64d@o532657.ingest.sentry.io/6100370',
  integrations: [Sentry.browserTracingIntegration()],
  environment: import.meta.env.DEV ? 'local' : 'server',
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  release: BUILD_ID || 'dev',
  enabled:
    import.meta.env.PROD || import.meta.env.VITE_SENTRY_ENABLED === 'true',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // TODO: We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Catch events and print them to the console
  beforeSend(event, hint) {
    console.error(hint.originalException ?? hint.syntheticException);
    // Don't send events in dev
    if (import.meta.env.DEV && import.meta.env.VITE_SENTRY_ENABLED !== 'true')
      return null;
    return event;
  },
});

export default Sentry;
